<template>
  <div class="page">
    <div class="page-main personnal">
      <el-card shadow="never" class="page-content">
        <el-form :model="personnalInformation" :rules="pInfoRules" ref="pInfoForm">
          <h3 v-if="!isLAN" style="padding-left: 170px; margin-bottom: 12px">{{ personnalInformation.enterpriseName }}</h3>
          <div class="basic">
            <span class="dot"></span>
            <span class="subhead">{{ $t('personnalInfo.baseInfo') }}</span>
          </div>
          <el-form-item :label="$t('personnalInfo.displayName') + ':'" required>
            <span v-show="isModifyName">{{ personnalInformation.userName }}</span>
            <el-input
              size="medium"
              v-model="personnalInformation.userName"
              v-show="!isModifyName"
            ></el-input>
            <span class="color1f" v-show="isModifyName" @click="modifyName">
              {{ $t('personnalInfo.modify') }}
            </span>
            <span class="color1f" v-show="!isModifyName" @click="saveName">
              {{ $t('personnalInfo.save') }}
            </span>
          </el-form-item>
          <el-form-item v-show="!isPersonal && !modifyEmailFlag " :label="$t('personnalInfo.email') + ':'">
            <span>{{ personnalInformation.email }}</span>
            <span  class="color1f" @click="modifyEmail">
              {{ $t('personnalInfo.modify') }}
            </span>
          </el-form-item>
          <modify-email v-if="modifyEmailFlag" inline-mode="true"/>
          <el-form-item :label="$t('personnalInfo.username') + ':'">
            <span>{{ personnalInformation.loginName }}</span>
          </el-form-item>
          <el-form-item v-show="!modifyPasswordFlag" :label="$t('personnalInfo.password') + ':'">
            <span>******</span>
            <span class="color1f" @click="modifyPassword">
              {{ $t('personnalInfo.modify') }}
            </span>
          </el-form-item>
          <modify-password v-if="modifyPasswordFlag" inline-mode="true"/>
          <el-form-item :label="$t('personnalInfo.countryRegion') + ':'">
            <span v-show="isModifyCountry">
              {{ personnalInformation.country }}
            </span>
            <el-select size="medium" v-model="personnalInformation.country" filterable v-show="!isModifyCountry">
              <el-option
                v-for="item in countryDics"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
            <span class="color1f" v-show="isModifyCountry" @click="modifyCountry">{{
              $t('personnalInfo.modify')
            }}</span>
            <span class="color1f" v-show="!isModifyCountry" @click="saveCountry">{{
              $t('personnalInfo.save')
            }}</span>
          </el-form-item>
          <el-form-item :label="$t('personnalInfo.timezone') + ':'">
            <span v-show="isModifyTime">
              {{ personnalInformation.timezoneDes }}
            </span>
            <el-select size="medium" v-model="personnalInformation.zoneId" v-show="!isModifyTime">
              <el-option
                v-for="item in timezoneList"
                :key="item.id"
                :label="item.description"
                :value="item.id"
              ></el-option>
            </el-select>
            <span class="color1f" v-show="isModifyTime" @click="modifyTime">
              {{ $t('personnalInfo.modify') }}
            </span>
            <span class="color1f" v-show="!isModifyTime" @click="saveTime">
              {{ $t('personnalInfo.save') }}
            </span>
          </el-form-item>
          <div class="roles">
            <span class="dot"></span>
            <span class="subhead">{{ $t('personnalInfo.roleInfo') }}</span>
          </div>
          <el-form-item :label="$t('user.role') + ':'">
            <span>{{ personnalInformation.role }}</span>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { queryUserInfo, modifyUserInfo, getTimeZoneListApi } from '@/api/app'
import store from '@/store'
import ModifyEmail from "@/views/Login/ModifyEmail";
import ModifyPassword from "@/views/Login/ModifyPassword";

export default {
  name: 'PersonnalInformation',
  components: {ModifyPassword, ModifyEmail},
  data() {
    return {
      modifyEmailFlag: false,
      modifyPasswordFlag: false,
      personnalInformation: {},
      pInfoRules: {
        userName: [
          {
            required: true,
            message: this.$t('requireTips'),
            trigger: 'blur',
          },
        ],
        businessId: [
          {
            required: true,
            message: this.$t('requireTips'),
            trigger: 'blur',
          },
        ],
      },
      isModifyName: true,
      isModifyCompany: true,
      isModifyCountry: true,
      isModifyTime: true,
      //不完整
      CountryLists: [
        {
          label: 'Saudi Arabia',
        },
        {
          label: 'Turkmenistan',
        },
        {
          label: 'Qatar',
        },
        {
          label: 'Nepal',
        },
        {
          label: 'Kazakhstan',
        },
        {
          label: 'Philippines',
        },
        {
          label: 'China',
        },
        {
          label: 'Iran',
        },
        {
          label: 'Armenia',
        },
        {
          label: 'Ireland',
        },
        {
          label: 'France',
        },
        {
          label: 'Albania',
        },
      ],

      timezoneList: [],
      isPersonal: store.getters.isPersonal,
    }
  },
  computed: {
    ...mapState('account', ['loginInfo']),
    ...mapState('session', ['isLAN']),
    ...mapGetters('dist', ['countryDics']),
  },
  created() {
    this.getPersonalInfo()
    this.getTimeZoneList()
  },
  mounted() {
    console.log(this.modifyEmailFlag);
  },
  methods: {
    // 时区
    getTimeZoneList() {
      getTimeZoneListApi()
        .then(({ data }) => {
          this.timezoneList = (data.result && data.result.rows) || []
        })
        .catch(() => {})
    },
    // 获取用户信息
    getPersonalInfo(isCommit) {
      let id = this.loginInfo.id
      queryUserInfo(id)
        .then((res) => {
          this.personnalInformation = res.data.result.rows[0] || {}
          if (isCommit) {
            this.$store.commit('account/SET_LOGIN_INFO', { loginInfo: res.data.result.rows[0] })
          }
        })
        .catch(() => {})
    },
    modifyUserInfo(key) {
      let params = {
        id: this.personnalInformation.id,
      }
      params[key] = this.personnalInformation[key]
      modifyUserInfo(params)
        .then((res) => {
          this.$message({
            message: res.data.message,
            type: 'success',
          })
          this.getPersonalInfo(true)
        })
        .catch(() => {})
    },
    // 修改邮箱
    modifyEmail() {
      this.modifyEmailFlag = !this.modifyEmailFlag;
    },
    // 修改密码
    modifyPassword() {
      this.modifyPasswordFlag = !this.modifyPasswordFlag;
    },
    // 修改姓名
    modifyName() {
      this.isModifyName = false
    },
    // 保存姓名
    saveName() {
      if (this.personnalInformation.userName !== '') {
        this.modifyUserInfo('userName')
        this.isModifyName = true
      } else {
        this.$message({
          type: 'error',
          message: this.$t('requireTips'),
        })
      }
    },
    // 修改公司
    modifyCompany() {
      this.isModifyCompany = false
    },
    // 保存公司
    saveCompany() {
      this.$refs.pInfoForm.validateField('businessId', (userNameError) => {
        if (userNameError == '') {
          this.modifyUserInfo('businessId')
          this.isModifyCompany = true
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 修改国家/时区
    modifyCountry() {
      this.isModifyCountry = false
    },
    // 保存国家/时区
    saveCountry() {
      this.modifyUserInfo('country')
      this.isModifyCountry = true
    },
    // 修改时间
    modifyTime() {
      this.isModifyTime = false
    },
    // 保存时间
    saveTime() {
      this.modifyUserInfo('zoneId')
      this.isModifyTime = true
    },
  },
}
</script>

<style lang="scss" scoped>
.personnal {
  padding: 30px;
  .page-content {
    .basic,
    .safety,
    .roles {
      width: 270px;
      text-align: right;
      margin: 30px 0 10px;
      .dot {
        width: 10px;
        height: 10px;
        display: inline-block;
        background-color: $--color-primary;
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;
      }
      .subhead {
        color: #082143;
        font-size: 16px;
        font-weight: 700;
        vertical-align: middle;
      }
    }
    .safety {
      width: 370px;
      text-align: left;
      .wenhao {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: #fff;
        background-color: #ccc;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
    ::v-deep .el-form-item__label {
      width: 280px;
      // line-height: 40px;
      padding: 0 12px 0 0;
    }
    ::v-deep .el-form-item__content {
      font-size: 16px;
      .el-form-item__error {
        position: relative;
        margin-left: 280px;
      }
    }
    ::v-deep .el-form-item {
      margin-bottom: 5px;
      width: 800px;
    }
    ::v-deep .el-input {
      width: 400px;
    }
    .color1f {
      color: #1f7ff5;
      float: right;
      cursor: pointer;
    }
  }
}
</style>
